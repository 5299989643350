












































import { Component, Prop, Vue } from 'vue-property-decorator';
import Card from '@/shared/resources/components/cards/Card.vue';
import ApiV1Private from '@/shared/lib/api/query-builders/ApiV1Private';
import ApiV1Settings from '@/core/api/settings/ApiV1Settings';
import AuthRegisterPasswordForm from '@/app/modules/auth/components/password/AuthRegisterPasswordForm.vue';
import AuthPasswordDataInterface from '@/app/modules/auth/interfaces/AuthPasswordDataInterface';
import AuthLayoutLogo from '@/app/layouts/auth/AuthLayoutLogo.vue';
import AuthRegisterPasswordCheck from '@/app/modules/auth/components/password/AuthRegisterPasswordCheck.vue';
import Alert from '@/shared/resources/components/Alert.vue';
import IconMessage from '@/shared/resources/components/IconMessage.vue';
import Auth from '@/app/lib/auth/Auth';
import { redirect } from '@/shared/lib/support/router/RouterUtils';
import UserCoreModel from '@/modules/users/models/UserCoreModel';
import LoaderInterval from '@/shared/resources/components/LoaderInterval.vue';

@Component({
  components: {
    LoaderInterval,
    IconMessage,
    Alert,
    Card,
    AuthRegisterPasswordCheck,
    AuthLayoutLogo,
    AuthRegisterPasswordForm,
  },
})
export default class AuthRegisterConfirm extends Vue {
  /**
   * Props
   */
  @Prop() private hash!: string;

  /**
   * Data
   */
  private data: AuthPasswordDataInterface = {
    password: '',
    passwordConfirm: '',
  };
  private loginTimeout: number = 10;
  private expandLogo: boolean = true;
  private loading: boolean = false;
  private user: UserCoreModel | null = null;
  private passwordSuccess: boolean = false;
  private errorMessage: string | null = null;

  /**
   * Display getters
   */
  private get displayPasswordForm(): boolean {
    return !!this.user && !this.passwordSuccess;
  }

  private get displayPasswordSuccess(): boolean {
    return this.passwordSuccess;
  }

  private get displayError(): boolean {
    return !!this.errorMessage;
  }

  /**
   * Methods
   */
  private async setPassword() {
    this.loading = true;
    this.errorMessage = null;

    try {
      const data = {
        hash: this.hash,
        password: this.data.password,
      };

      await new ApiV1Private()
        .schema(ApiV1Settings.schema)
        .data(data)
        .patch('/v2/contractors-auth/set-password');

      this.expandLogo = false;
      this.passwordSuccess = true;
      this.setLoginTimeout();
    } catch (ex) {
      this.errorMessage = 'Wystąpił nieznany błąd. Prosimy o&nbsp;kontakt';
    }

    this.loading = false;
  }

  private async setLoginTimeout() {
    setTimeout(async () => {
      this.loading = true;

      try {
        await Auth.login(this.user!.email, this.data.password);
        redirect({ name: 'dashboard' });
      } catch (ex) {
        // TODO
      }
    }, (this.loginTimeout + 1) * 1000);
  }

  /**
   * Handlers
   */
  private onUserChecked(user: UserCoreModel | null) {
    this.user = user;
  }

  private onPasswordFormSubmit() {
    this.setPassword();
  }
}
