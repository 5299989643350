


















import { Vue, Component, Prop } from 'vue-property-decorator';
import Loader from '@/shared/resources/components/Loader.vue';
import ApiV1Private from '@/shared/lib/api/query-builders/ApiV1Private';
import ApiV1Settings from '@/core/api/settings/ApiV1Settings';
import IconMessage from '@/shared/resources/components/IconMessage.vue';
import UserCoreModel from '@/modules/users/models/UserCoreModel';

@Component({
  components: {
    IconMessage,
    Loader,
  },
})
export default class AuthRegisterPasswordCheck extends Vue {
  /**
   * Props
   */
  @Prop() private hash!: string;

  /**
   * Data
   */
  private loading: boolean = false;
  private errorMessage: string | null = null;

  /**
   * Getters
   */
  private get displayLoader(): boolean {
    return this.loading;
  }

  private get displayErrorInfo(): boolean {
    return !!this.errorMessage && !this.loading;
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.getRegistration();
  }

  /**
   * Methods
   */
  private async getRegistration() {
    this.loading = true;

    try {
      const response = await new ApiV1Private()
        .schema(ApiV1Settings.schema)
        .where('hash', this.hash)
        .get('/v2/contractors-auth/get-user-by-hash');

      const result: UserCoreModel | null = response.data
        ? new UserCoreModel(response.data)
        : null;

      this.$emit('checked', result);
    } catch (ex) {
      if (ex.isNotFound()) {
        this.errorMessage = 'Konto nie istnieje lub nie zostało jeszcze zweryfikowane.';
      } else {
        this.errorMessage = 'Wystąpił nieoczekiwany błąd. Prosimy o&nbsp;kontakt.';
      }

      this.$emit('checked', null);
    }

    this.loading = false;
  }
}
